import axios from 'axios';
import { useEffect, useState } from 'react';
import UserDTO from '../dtos/UserDTO';

export function setAuthSession(content: any) {
  // Construye la cadena de la cookie con el nombre, valor y fecha de expiración
  document.cookie = `user=${encodeURIComponent(content.username)};  expires=${new Date(content.refreshExpires * 1000).toUTCString()}; path=/;`;
  document.cookie = `token=${encodeURIComponent(content.token)};  expires=${new Date(content.expires * 1000).toUTCString()}; path=/;`;
  document.cookie = `refreshToken=${encodeURIComponent(content.refreshToken)};  expires=${new Date(content.refreshExpires * 1000).toUTCString()}; path=/;`;
}

export async function refreshToken() {
  const currentRefreshToken = getCookie('refreshToken');
  if (!currentRefreshToken) return;
  const response:any = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=refresh`, JSON.stringify(({ token: currentRefreshToken })));
  if (!response.data.success) throw new Error(response.data.content.error)
  setAuthSession(response.data.content);
  return response.data.content.token;
}

function getCookie(name: string) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? decodeURIComponent(match[2]) : null;
}

export function removeAuthSession() {
  // Establece la cookie de autenticación con una fecha de expiración en el pasado para eliminarla
  document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getCredentials() {
  const cookie = getCookie('token');
  return cookie ? `token=${getCookie('token')}` : '';
}

export default function useAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserDTO | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const signOut = async ()=>{
    removeAuthSession();
    localStorage.clear();
    sessionStorage.clear();
    await fetchUser();
  }

  async function fetchUser() {
    setIsAuthenticated(false)
	const user = getCookie('user');
	const token = getCookie('token');
    if (!user) {
		setIsLoading(false);
		return;
	}
	if (!token) await refreshToken();
    
    setUser({ name: String(user) });
    setIsAuthenticated(true);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchUser();
  }, []);

 
  return { isLoading, isAuthenticated, user , signOut };
}





